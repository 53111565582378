import { iframeResizer } from 'iframe-resizer';
import { SBLIVE_WEB_BASE_URL } from 'constants.js.erb';

function toUnderscore(str: string) {
  return str.replace(/(?:^|\.?)([A-Z])/g, (a, b) => `_${b.toLowerCase()}`);
}

const PARAMS = ['sportId', 'genderId', 'date'];

function buildUrl(sblWidgetId: string, filters: Record<string, string | undefined>) {
  const base = `${SBLIVE_WEB_BASE_URL}/widgets/v4/${sblWidgetId}`;
  const queryString = PARAMS.filter(attribute => !!filters[attribute])
    .map(attribute => `${toUnderscore(attribute)}=${filters[attribute]}`)
    .join('&');
  return [base, queryString].join('?');
}

function widgets() {
  [...document.querySelectorAll<HTMLElement>('[data-sbl-widget-id]')].forEach(widget => {
    if (widget.children.length > 0) return;
    const { sblWidgetId, ...filters } = widget.dataset;
    if (!sblWidgetId) return;

    const iframe = document.createElement('iframe');
    iframe.style.width = '1px';
    iframe.style.minWidth = '100%';
    iframe.style.border = '0px';
    iframe.src = buildUrl(sblWidgetId, filters);

    widget.appendChild(iframe);
    iframeResizer({}, iframe);
  });

  [...document.querySelectorAll<HTMLElement>('[data-sbl-game-score-id]')].forEach(widget => {
    if (widget.children.length > 0) return;

    const iframe = document.createElement('iframe');
    iframe.style.height = '160px';
    iframe.style.width = '1px';
    iframe.style.minWidth = '100%';
    iframe.style.border = '0px';
    iframe.src = `${SBLIVE_WEB_BASE_URL}/widgets/game-score/${widget.dataset.sblGameScoreId}`;

    widget.appendChild(iframe);
    iframeResizer({}, iframe);
  });
}

if (document.readyState == 'complete') {
  widgets();
} else {
  window.addEventListener('load', widgets);
}

// @ts-ignore
window.SBL = { widgets };
