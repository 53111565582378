import { iframeResizer } from 'iframe-resizer';
import { SBLIVE_WEB_BASE_URL } from 'constants.js.erb';

function toUnderscore(str: string) {
  return str.replace(/(?:^|\.?)([A-Z])/g, (a, b) => `_${b.toLowerCase()}`);
}

const PARAMS = ['sportId', 'genderId', 'date'];

function buildWidgetUrl(version: 'v4' | 'v5', sblWidgetId: string, filters: Record<string, string | undefined>) {
  const base = `${SBLIVE_WEB_BASE_URL}/widgets/${version}/${sblWidgetId}`;
  const queryString = PARAMS.filter(attribute => !!filters[attribute])
    .map(attribute => `${toUnderscore(attribute)}=${filters[attribute]}`)
    .join('&');
  return [base, queryString].join('?');
}

function createEmbed(widget: HTMLElement, url: string) {
  if (widget.children.length > 0) return;

  const iframe = document.createElement('iframe');
  iframe.style.width = '1px';
  iframe.style.minWidth = '100%';
  iframe.style.border = '0px';
  iframe.style.height = '100%';
  iframe.src = url;

  widget.appendChild(iframe);
  iframeResizer({}, iframe);
}

function widgets() {
  [...document.querySelectorAll<HTMLElement>('[data-sbl-widget-id]')].forEach(widget => {
    const { sblWidgetId, ...filters } = widget.dataset;
    if (!sblWidgetId) return;

    createEmbed(widget, buildWidgetUrl('v4', sblWidgetId, filters));
  });

  [...document.querySelectorAll<HTMLElement>('[data-sbl-game-score-id]')].forEach(widget => {
    createEmbed(widget, `${SBLIVE_WEB_BASE_URL}/widgets/game-score/${widget.dataset.sblGameScoreId}`);
  });

  [...document.querySelectorAll<HTMLElement>('[data-sbl-tournament-id]')].forEach(widget => {
    createEmbed(widget, `${SBLIVE_WEB_BASE_URL}/widgets/tournaments/${widget.dataset.sblTournamentId}`);
  });
}

if (document.readyState === 'complete') {
  widgets();
} else {
  window.addEventListener('load', widgets);
}

// @ts-ignore
window.SBL = { widgets };
